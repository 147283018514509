import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./brandGrid.css";
import { fetchBannerData } from "../../../api/HomePageApi";
import Loader from "../../common/loader/loadingCard";
import { ReactComponent as LeftArrow } from '../../../assests/icons/LeftArrow.svg';


const BrandGrid = () => {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const brandData = await fetchBannerData();
        const flattenedBrands = Object.values(brandData?.Advertisement?.TopBrandsAd).flat();
        setBrands(flattenedBrands);
      //  console.log(brandData,"TopBrandsAd")
      } catch (error) {
        console.error("Error fetching brands:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.3,
    slidesToScroll: 1,
    touchThreshold: 100,
    rows: 2,
  };

  if (loading) {
    return (
      <div className="loading-spinner">
        <div className="product-list">
          <Loader />
          <Loader />
          <Loader />
          <Loader />
          <Loader />
        </div>
      </div>
    );
  }

  // Remove the last item if the number of brands is odd
  const adjustedBrands = brands.length % 2 === 0 ? brands : brands.slice(0, -1);

  // Split adjusted brands into chunks of 4 for each slide
  const chunkSize = 4;
  const chunkedBrands = [];
  for (let i = 0; i < 8; i += chunkSize) {
    chunkedBrands.push(adjustedBrands.slice(i, i + chunkSize));
  }

  return (
    <div className="brand__grid__wrapper section_space">
      <div className="brand__grid__component page-width">
        <div className="hm__prod__slider-title">
          <h2>Top Brands</h2>
          <div className="hm__prod__desktop__arrow">
            <Link to={"/brands"}>View all</Link>
          </div>
          <div className="hm__prod__mobile__arrow">
            <Link to={"/brands"}><LeftArrow /></Link></div>
        </div>
        <div className="brand__slider-container">
          {isMobile ? (
           <div className="mob_brand_sl">
              
                <div  className="brand__slider__slide">
                <Slider {...sliderSettings}>
                    {brands.slice(0, 8).map((brand, index) => (
                      <div key={index} className="brand__content">
                        <a
                          href={`/brands/${brand.Name
                            .replace(/[^a-zA-Z0-9 ]/g, "")
                            .replace(/\s+/g, "-")
                            .toLowerCase()}?id=${brand.id}`}
                          className="brand__link"
                        >
                          <div className="brand__img">
                            <img src={brand.MobileBrandImage} alt={brand.name} />
                          </div>
                          <div className="brand__bottom">
                            <img src={brand.MobileBrandLogo} alt={brand.name} />
                          </div>
                        </a>
                      </div>
                    ))}
                  </Slider>
                </div>
                {/* {chunkedBrands.slice(0, 8).map((chunk, chunkIndex) => (
              ))} */}
            </div>
          ) : (
            <div className="brand__grid">
              {brands.slice(0, 8).map((brand, index) => (
                <div key={index} className="brand__content">
                  <a
                    href={`/brands/${brand.Name
                      .replace(/[^a-zA-Z0-9 ]/g, "")
                      .replace(/\s+/g, "-")
                      .toLowerCase()}?id=${brand.id}`}
                    className="brand__link"
                  >
                    <div className="brand__img">
                      <img src={brand.BrandBanner} alt={brand.Name} />
                    </div>
                    <div className="brand__bottom">
                      <img src={brand.BrandLogo} alt={brand.Name} />
                    </div>
                  </a>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BrandGrid;
