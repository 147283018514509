import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import ProductCard from '../../layout/product/productCard/brandPlacementCard';
import '../../layout/product/productCard/productCard.css';
import Loader from '../loader/loadingCard';
import Logo from "../../../assests/logo/unfitLogoWhite.png";
import { Link } from "react-router-dom";
import { fetchProducts } from '../../../api/prodApi';
import { fetchBannerData } from "../../../api/HomePageApi";
import ProductCard from '../../layout/product/productCard/productCard';

const ExtraProdSlider = ({ categoryName, categoryId,setIsToastMessage,setCountItems }) => {
  const [products, setProducts] = useState([]);
  const [homeData, setHomeBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const isMounted = useRef(false);
  const [currentSelected, setCurrentSelected] = useState([]);


  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      loadProducts(page);
    }
  }, [page]);

  const loadProducts = async (page) => {
    setLoading(true);
    try {
      const fetchedProducts = await fetchProducts(categoryId, categoryName, page);
      const fetchedBannerData = await fetchBannerData(categoryName);

      setProducts(fetchedProducts);
      setHomeBanner(fetchedBannerData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

 
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    arrows: true,
    touchThreshold: 100,
    responsive: [
      { breakpoint: 1440, settings: { slidesToShow: 4, slidesToScroll: 1 } },
      { breakpoint: 1024, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 850, settings: { slidesToShow: 2, slidesToScroll: 1 } },
    ]
  };

  const brandPlacement = homeData?.Advertisement?.BrandPlacement;
  // console.log('brandPlacement', brandPlacement)
  const brandPlacementProduct = brandPlacement?.ProductList || [];

  // console.log(brandPlacement, 'logo')

  const handleInitialAddToCart = (product) => {
    setCurrentSelected((prev) => {
      if (prev.includes(product.id)) {
        return prev.filter((each) => each !== product.id);
      } else {
        return [product.id];
      }
    });
  };
  return (
    <div className="hm__product-container section_space bolt_nutritions hm__prod__slider">
      {loading ? (
        <div className="loading-spinner">
          <div className='product-list'>
            <Loader />
            <Loader />
            <Loader />
            <Loader />
            <Loader />
          </div>
        </div>
      ) : (
        <div className='slider_container cm_arrow_side'>
          <div className="extra__card__item">
            <div className='extra__product__card-item'>
              <div className="extra__product__card-inner" style={{ backgroundColor: brandPlacement?.card_color }}>
                <div className="card" >

                  <img src={brandPlacement?.BrandLogo || Logo} alt='Unfit Mart' />
                  <h3>{brandPlacement?.BrandName}</h3>
                  <p>{brandPlacement?.brandDescription}</p>
                  <Link to={`/brands/${brandPlacement?.BrandName
                    .replace(/\s+/g, "-").toLowerCase()}`+`?id=${brandPlacement?.id}`}>
                    <div className="buy__button buy__now__btn">Shop Now</div>
                  </Link>
                </div>
              </div>
            </div>
            <Slider className="bolt_nutri_slide" {...sliderSettings}>
              {brandPlacementProduct.map((product) => (
                <ProductCard key={product.id} product={product} BrandPlacementProduct={brandPlacementProduct} onAddtoCart={handleInitialAddToCart}
                  currentSelected={currentSelected}
                  setIsToastMessage={setIsToastMessage}
                  setCountItems={setCountItems} />
              ))}
            </Slider>
          </div>
        </div>
      )
      }
    </div >
  );
}

export default ExtraProdSlider;
