import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductCard from "../../layout/product/productCard/productCard";
import "../../layout/product/productCard/productCard.css";
import Loader from "../loader/loadingCard";
import { fetchProducts } from "../../../api/prodApi";
import { ReactComponent as LeftArrow } from "../../../assests/icons/LeftArrow.svg";

const ProductSlide = ({ categoryName, categoryId, setIsToastMessage ,setCountItems}) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading state
  const [page, setPage] = useState(1); // State for pagination
  const isMounted = useRef(false);
  const [currentSelected, setCurrentSelected] = useState([]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      loadProducts(page);
    }
  }, [page]);

  const loadProducts = async (page) => {
    setLoading(true);
    try {
      const products = await fetchProducts(categoryId, categoryName, page);
      setProducts(products);
      setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false); // Set loading to false on error
    }
  };

 

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    touchThreshold: 100,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let categoryReplace = decodeURIComponent(categoryName);

  const handleInitialAddToCart = (product) => {
    setCurrentSelected((prev) => {
      if (prev.includes(product.id)) {
        return prev.filter((each) => each !== product.id);
      } else {
        return [product.id];
      }
    });
  };
  return (
    <div className="hm__product-container section_space hm__prod__slider appeals">
      <div className="page-width">
        <div className="hm__prod__slider-title">
          <h2>{categoryName}</h2>
          <div className="hm__prod__desktop__arrow">
            <Link to={`category/${categoryReplace}?viewId=${8}`}>View All</Link>
          </div>
          <div className="hm__prod__mobile__arrow">
            <Link to={`category/${categoryReplace}?viewId=${8}`}>
              <LeftArrow />
            </Link>
          </div>
        </div>
      </div>

      <div className="slider_container cm_arrow_side">
        {loading ? (
          <div className="loading-spinner">
            <div className="product-list">
              <Loader />
              <Loader />
              <Loader />
              <Loader />
              <Loader />
            </div>
          </div> // Display loader while fetching data
        ) : (
          <Slider {...sliderSettings}>
            {products.map(
              (product) =>
                product.Type === "product" && (
                  <ProductCard
                    key={product.id}
                    product={product}
                    onAddtoCart={handleInitialAddToCart}
                    currentSelected={currentSelected}
                    isOutOfStock={product?.VariantDetails?.[0]?.InStock === "no"}
                    setIsToastMessage={setIsToastMessage}
                    setCountItems={setCountItems}
                  />
                )
            )}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default ProductSlide;
