// src/api/ProductListApi.js
import { BLOGPOST,RECOMMENDEDBLOG } from "../Constants";
const BASE_URL =  BLOGPOST;

// Caching and promise management
let cachedBlogData = null;
let fetchBlogPromise = null;

// Fetch Blog List Data
export const fetchBlogListData = async () => {
  //console.log("In blog api")
  if (cachedBlogData) {
    return cachedBlogData;
  }

  if (fetchBlogPromise) {
    return fetchBlogPromise;
  }

  fetchBlogPromise = new Promise(async (resolve, reject) => {
    try {
      const payload = new URLSearchParams({
        Pagination: '',
        groupCategoryId: '',
        LevelType: ''
      });

      const response = await fetch(BASE_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: payload,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      cachedBlogData = data || [];
      resolve(cachedBlogData);
    } catch (error) {
      reject(error);
    } finally {
      fetchBlogPromise = null;
    }
  });

  return fetchBlogPromise;
};
let cachedRecommenedBlogData = null;

export const fetchRecommenedBlogListData = async () => {
  //console.log("In blog api")
  let  recommenedBlogData
  if (cachedRecommenedBlogData) {
    return cachedRecommenedBlogData;
  }
try{


      const response = await fetch(RECOMMENDEDBLOG, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
     
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      recommenedBlogData = data.Result || [];
     // console.log(recommenedBlogData);
      cachedRecommenedBlogData=recommenedBlogData
    } catch (error) {
      console.log(error);
    } 
 

  return recommenedBlogData;
};


