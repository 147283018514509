import { Link } from "react-router-dom";
import "./subCategoryMenu.css";




const SubCategoryMenu = ({
  selectedNavSubDetails,
  selectedNav,
  setCategoryChange,
  openCategory,categoryData
}) => {




  const handleClick = (SubCAtegoryName, selectedNav) => {
    let mainBody = document.querySelector("body");
    // console.log(categoryData,"SubCAtegoryName")
    mainBody.classList.remove("minicart-active");
    // if(!mainBody.classList.contains("minicart-active")){
    //   setTimeout(()=>{
    //     window.location.reload();
    //   },500)
    // }

    setCategoryChange({
      selectedCategory: selectedNav,
      selectedSubCategory: SubCAtegoryName,
    });
    if (openCategory) {
      document.getElementById("menuDrawer").style.width = "0px";
    }
  };

  // const formatCategoryName = (name) =>
  //   `${name}`
  //     .replace(/[^a-zA-Z0-9\s-]/g, "")
  //     .replace(/\s+/g, "-")
  //     .replace(/-$/, "")
  //     .replace(/--+/g, "-")
  //     .toLowerCase();
  const formatCategoryName = (name) =>
    encodeURIComponent(name)

  
  return !openCategory ? (
    <div className="nav_sub_menu">
      <ul>
        {selectedNavSubDetails.map((sub,index) => (
          <li key={sub.id}>
            <Link
              className="nav_sub_list"
              id={sub.id}
              to={`/category/${formatCategoryName(selectedNav).replace(/%20/g, "+")?.replace("%26","&")}/${formatCategoryName(sub.category_name)?.replace(/%20/g, "+")?.replace("%26","&")}?id=${sub?.id}`}
              onClick={() => handleClick(sub.category_name, selectedNav)}
            >
              {sub.category_name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <ul className="subCategoryMenu">
      {selectedNavSubDetails.map((sub, index) => (
        index < 8 ? (
          <li key={sub.id}>
            <Link
              className="nav_sub_list"
              id={sub.category_name}
              to={`/category/${formatCategoryName(selectedNav)?.replace(/%20/g, "+")?.replace("%26","&")}/${formatCategoryName(sub.category_name)?.replace(/%20/g, "+")?.replace("%26","&")}?id=${sub?.id}`}
              onClick={() => handleClick(sub.category_name, selectedNav)}
            >
              {sub.category_name}
            </Link>
          </li>
        ) : null
      ))}
    </ul>
  );
};

export default SubCategoryMenu;
