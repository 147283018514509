import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageComponent from '../../image/mainImageComp';
// import { OfferPrevArrow, OfferNextArrow } from '../homeSlider/customArrow/customArrow';
import { useMediaQuery } from 'react-responsive';
import { fetchBannerData } from '../../../../api/HomePageApi';
// import { ReactComponent as LeftArrow } from '../../../../assests/icons/LeftArrow.svg';
import { Link } from "react-router-dom";

const DefaultSliderImages = [
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 1'
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 2'
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 3'
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 4'
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 5'
  }
];

const OfferBannerCard = () => {
  const sliderRef = useRef(null);
  // const [currentSlide, setCurrentSlide] = useState(0);


  const [sliderImages, setSliderImages] = useState(DefaultSliderImages);
  // const [isLoading, setIsLoading] = useState(true);
  // const [AmazingBrandData, setAmazingBrandData] = useState([]);

  useEffect(() => {
    let isMounted = true; // track if component is mounted
    const loadBannerData = async () => {
      try {
        const data = await fetchBannerData();
        const banners = data.Advertisement?.BrandDiscountAd || [];
        // setAmazingBrandData(data);
        if (banners.length > 0) {
          const formattedImages = banners.map(banner => ({
            mobileSrc: banner.MobileDiscountBanner?.trim() || '/images/default-mobile.png',
            desktopSrc: banner.DiscountBanner?.trim() || '/images/default-desktop.png',
            alt: banner.alt || 'Default Image',
            url: banner.BrandName,
            id: banner.DiscountFor
          }));
          if (isMounted) {
            setSliderImages(formattedImages);
          }
        } else {
          // console.warn('No banners found, using default images.');
        }
      } catch (error) {
        console.error('Error fetching banner data:', error);
        if (isMounted) {
          setSliderImages(DefaultSliderImages);
        }
      } finally {
        if (isMounted) {
          // setIsLoading(false);
        }
      }
    };

    loadBannerData();

    return () => {
      isMounted = false; // cleanup function to set isMounted to false when the component unmounts
    };
  }, []); 

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });


  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2.45,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    touchThreshold: 100,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.45,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.45,
          slidesToScroll: 1,
          dots:false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots:true
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        }
      }
    ]
  };
  

  return (
    <div className="offer__banner__wrapper section_space">
      <div className="offer__banner__container amazing__brand page-width">
        <div className="hm__prod__slider-title less_space_title">
          <h2>Amazing Brand Offers for you</h2>
          {/* <div className="hm__prod__mobile__arrow"><LeftArrow /></div> */}
        </div>
      </div>
      <div className="banner_sl_container">
        <Slider ref={sliderRef} {...sliderSettings} className="cm_banner_slider cm_arrow_side">
          {sliderImages.map((image, index) => (
            <Link
              key={image.id || index} 
              to={`/brands/${image.url?.replace(/[^a-zA-Z0-9 ]/g, "")?.replace(/\s+/g, "-").toLowerCase()}?id=${image.id}`}
            >
              <div>
                <ImageComponent
                  src={isMobile ? image.mobileSrc : image.desktopSrc}
                  alt={image.alt}
                  height={'320px'}
                  pageWidth={isMobile ? "100%" : "1200px"}
                />
              </div>
            </Link>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default OfferBannerCard;
