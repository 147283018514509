import React, { useState, useEffect, useRef } from "react";
import CartItem from "./cartItem";

import Imoji from "../../../assests/icons/emoji.png";
import CartImage from "../../../assests/image/emptyCart.png";
import locationIcon from "../../../assests/image/location.png";
import addIcon from "../../../assests/image/Group 143726454.png";
import ShippingIcon from "../../../assests/icons/truck-tick.png";
import BankIcon from "../../../assests/image/bank.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

import UpdateAddress from "../customer/account/address";
import placeCodOrder from "./codOrder";
import createOrder from "./createOrder";
import { toast } from "react-toastify";
import OrderFailedPopup from "./popups/orderFailedPopup";
import ConfirmCOD from "./popups/confirmCOD";
import {
  fetchRegisterId,
  fetchUserData,
  WEB_CARTLIST,
  WEB_ADDRESS,
  PROMO_CODE,
} from "../../../Constants";
import Cookies from "js-cookie";
import {
  coupon_icon,
  cross_black,
  green_check,
  left_icon,
  off_01,
  right_arrow_bk,
  right_icon,
} from "../../../utils/image";
import CustomLoader from "../../common/customLoader/customLoader";
import fetchCartListApi from "../../../api/fetchCartListApi";


const CheckOutPage = ({ onItemRemove, localQuantity, setCountItems }) => {
  const location = useLocation()
  const [sessionError, setSessionError] = useState(null);
  const [isApplyCoupon, setisApplyCoupon] = useState(false);
  const [appliedCoupon, setAppliedCoupon] = useState(location?.state?.appliedCoupon || "");
  const [appliedCouponTitle, setAppliedCouponTitle] = useState("");
  const [PromoCode, setPromoCode] = useState(null);
  const [addres, setAddress] = useState(false);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState({
    Result: [],
    FinalPayableAmount: 0,
    ServiceFee: 0,
    discount: 0,
    SubTotal: 0,
  });
  const [displayLoginPages, setDisplayLoginPages] = useState({
    displayLogin: false,
    displayAccountPage: false,
    displayAddressPage: false,
    accountVerified: false,
  });
  const [countItems, setCountItem] = useState([]);
  const [paymentType,setPaymentType] = useState("Prepaid");
  const [total, setTotal] = useState({
    totalAmount: 0,
    finalAmount: 0,
  });
  const [displayAddresss, setDisplayAddress] = useState(null);
  const [confirmCODPopUp, setconfirmCODPopUp] = useState(false);
  const [OTPVerified, setOTPVerified] = useState(false);
  const [successfullPopUp, setSuccessfullPopUp] = useState(false);
  const [failedOrder, setFailedOrder] = useState(false);
  const [order, setOrder] = useState("");
  const [userDataObj, setUserDataObj] = useState(null); // Add state for userDataObj
  const [addressForm, setAddressForm] = useState({
    building: "",
    street: "",
    landmark: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
  }); // State for address form
  const [isLoadingCOD, setLoadingCOD] = useState(false);
  const cartItemRefs = useRef([]);
  const navigate = useNavigate();
  let modal = document.getElementById("myModal");
  let successfullmodal = document.getElementById("successfullModal");
  const isInitialRender = useRef(true);
  const isInitialRenderAppliedCoupon = useRef(true);

  const [PromoCodeDetails, setPromoCodeDetails] = useState({
    isOpen: false,
  });
  window.onclick = function (event) {
    if (event.target === modal) {
      modal.style.display = "none";
    }
  };

  const updateAddress = () => {
    const addAddress = async () => {
      try {
        const response = await fetch(WEB_ADDRESS, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            RegisterId: fetchRegisterId(),
            HouseNumber: addres.building + " |",
            Street: addres.street,
            LandMark: addres.landmark,
            Country: addres.country,
            State: addres.state,
            City: addres.city,
            PostalCode: addres.pincode,
          }),
        });

        if (!response.ok) {
          throw new Error(
            `Failed to fetch cart items! Status: ${response.status}`
          );
        }

        const updatedAddress = await response.json();
        fetchAddress();
      } catch (error) {
        setSessionError(error.message);
      }
    };
    addAddress();
  };
  const doesUserHaveAddress = () => {

    return (
      !!Object.values(displayAddresss || {})?.filter((res) => res !== "")
        ?.length > 0
    );
  };
  const fetchAddress = () => {
    const fetchExistingAddress = async () => {
      try {
        let registerID = fetchRegisterId();

        if (fetchUserData()) {
          const response = await fetch(WEB_ADDRESS, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              RegisterId: registerID,
            }),
          });

          if (!response.ok) {
            throw new Error(
              `Failed to fetch address! Status: ${response.status}`
            );
          }

          const fetchedAddress = await response.json();
          const existingAddress = fetchedAddress.Result[0];
          setDisplayAddress({
            houseNumberAndStreet: existingAddress.HouseNumberAndStreet,
            landmark: existingAddress.Landmark,
            pincode: existingAddress.PostalCode,
            state: existingAddress.State,
            city: existingAddress.City,
            country: existingAddress.Country,
          });
        }
      } catch (error) {
        setSessionError(error.message);
      }
    };

    fetchExistingAddress();
  };
  const fetchPromoCode = async () => {
    try {
      const RegisterId = fetchRegisterId();
      const payload = new URLSearchParams({
        RegisterId,
      });
      const response = await fetch(PROMO_CODE, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: payload,
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch product details! Status: ${response.status}`
        );
      }

      const data = await response.json();
      setPromoCode(data.Result);
    } catch (err) {
      // setError(err.message);
    } finally {
    }
  };

  useEffect(() => {
    const fetchUserData = () => {
      const userData = Cookies.get("userData")
        ? JSON.parse(Cookies.get("userData"))
        : null;
      setUserDataObj(userData);
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (addres) {
      updateAddress();
    }
  }, [addres]);
  const fetchCartItems = async () => {
    try {
      const RegisterId = fetchRegisterId();
      let response;
      let registerID;

      registerID = RegisterId; //JSON.parse(Cookies.get("resigterId")).RegisterId;
      response = await fetch(WEB_CARTLIST, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          RegisterId: RegisterId,
          CouponApplyCode: appliedCoupon,
          PaymentType: paymentType,
          ApplicationType: "web",
        }),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch cart items! Status: ${response.status}`
        );
      }

      const cartItemsData = await response.json();
      setItems(cartItemsData || {});
      setCountItem(cartItemsData.Result);
      if (cartItemsData?.StatusMessage == "Coupon Code Invalid") {
        toast.error("Coupon Code Invalid");
        handleRemoveCoupon();
      }
    } catch (error) {
      debugger;
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (userDataObj) {
      fetchAddress();
      fetchPromoCode();
      fetchCartItems();
    }
  }, [userDataObj]);

  useEffect(() => {
    //fetchAddress();

    if (isInitialRender.current) {
      // On initial render, just set the ref to false
      isInitialRender.current = false;
      return;
    }
    if (paymentType) {
      fetchCartItems();
    }
  }, [paymentType]);
  useEffect(() => {
    cartItemRefs.current = items.Result?.map(() => React.createRef()) || [];
  }, [items.Result]);

  useEffect(() => {
    items.Result?.forEach((item, index) => {
      if (cartItemRefs.current[index]?.current) {
        cartItemRefs.current[index].current.setAttribute(
          "data-line-item",
          item.id
        );
      }
    });
  }, [items.Result]);

  useEffect(() => {
    let totalSum = 0;
    let totalDiscountedPrice = 0;
    let totalDiscountOffered = 0;
    let finalPayableAmount = 0;
    countItems.forEach((item) => {
      totalSum += item.FinalPriceWithQty;
    });
    countItems.forEach((item) => {
      totalDiscountedPrice += item.FinalDiscountPriceWithQty;
    });

    totalDiscountOffered = totalSum - totalDiscountedPrice;
    finalPayableAmount =
      totalSum - items.discount + Number.parseInt(items.ServiceFee);
    setTotal({
      totalAmount: totalSum,
      finalAmount: finalPayableAmount,
      finalAmoutAfterDiscount:
        totalDiscountedPrice + Number.parseInt(items.ServiceFee),
      totalDiscount: totalDiscountOffered,
    });
  }, [countItems]);

  useEffect(() => {
    if (
      displayLoginPages.displayLogin ||
      displayLoginPages.displayAddressPage
    ) {
      modal.style.display = "block";
    }
  }, [displayLoginPages]);

  useEffect(() => {
    if (successfullmodal && confirmCODPopUp) {
      successfullmodal.style.display = "block";
    } else if (successfullmodal && !confirmCODPopUp && OTPVerified) {

      placeCodOrder({
        items,
        total,
        userDataObj,
        navigate,
        displayAddresss,
        setconfirmCODPopUp,
        setFailedOrder,
        setOrder,
        setLoadingCOD,
      });
    }
  }, [confirmCODPopUp]);

  const handleAddress = (e) => {
    setDisplayLoginPages({
      ...displayLoginPages,
      displayAddressPage: true,
      accountVerified: true,
    });
    const { name, value } = e.target;
    setAddressForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };


  const checkoutBtn = () => {
    const paymentMode = document.querySelector(
      'input[name="orderType"]:checked'
    )?.value;

    if (!paymentMode) {
      toast.error("Please select a payment mode.");
      return;
    }

    if (!fetchUserData()) {
      setDisplayLoginPages({ ...displayLoginPages, displayLogin: true });
      // } else if (fetchUserData().id === "") {
      //   setTimeout(() => {
      //     navigate("/cart", { state: { cartItems: items.Result, paymentMode } });
      //   }, 1000);
    } else if (!doesUserHaveAddress()) {
      toast.error("Please enter your Address to continue");
      setDisplayLoginPages({ ...displayLoginPages, displayAddressPage: true });
    } else if (paymentMode === "cod") {
      setconfirmCODPopUp(true);
    } else if (paymentMode === "prePaid") {
      createOrder({
        items,
        total,
        userDataObj,
        displayAddresss,
        items,
        navigate,
      });
    }
  };

  const toggleApplyCoupon = () => {
    setisApplyCoupon(!isApplyCoupon);
    document.querySelector(".header__wrapper")?.classList.remove("sticky");
  };

  useEffect(() => {
    if (isApplyCoupon) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflow = "auto";
    }
  }, [isApplyCoupon]);

  const handlePromoCodeAdd = (title, promoCode) => {
    setAppliedCouponTitle(title);
    setAppliedCoupon(promoCode);
    toggleApplyCoupon();
  };

  const handleRemoveCoupon = () => {
    setAppliedCouponTitle("");
    setAppliedCoupon("");
  };

  const PromoCodeInput = PromoCode?.find(
    (coupon) => coupon?.CouponCode === appliedCoupon
  );

  useEffect(() => {
  
    if (isInitialRenderAppliedCoupon.current) {
      // On initial render, just set the ref to false
      isInitialRenderAppliedCoupon.current = false;
   
      return;
    }
    if (!appliedCoupon) {
     
      fetchCartItems();
    }
    if (appliedCoupon === PromoCodeInput?.CouponCode) {
      
      fetchCartItems();
    }
  }, [appliedCoupon]);

  const isCartEmpty = items.Result?.length === 0;

  if (loading || isLoadingCOD) {
    return <CustomLoader />;
  }

  const togglePromoCodePopUp = (promo) => {
    setPromoCodeDetails((prev) => ({ ...promo, isOpen: !prev.isOpen }));
  };

  const paymentMode = document.querySelectorAll(
    'input[type="radio"][name="orderType"]'
  );
  if (paymentMode) {
    paymentMode.forEach((mode) => {
      mode.addEventListener("change", () => {
        // Get the value of the selected radio button
        const selectedValue = document.querySelector(
          'input[name="orderType"]:checked'
        ).value;
        switch (selectedValue) {
          case "prePaid":
            setPaymentType("Prepaid");
            break;
          case "cod":
            setPaymentType("COD");
            break;
        }
      });
    });
  }

  let handleCouponChange = (event) => {
    setAppliedCoupon(event.target.value);
    let promoInputError = document.querySelector(".promocode__input__error");
    if (event.target.value === "") {
      promoInputError?.classList?.add("hide");
    }
  };

  let discountApply = () => {
    let promoInputError = document.querySelector(".promocode__input__error");
    if (appliedCoupon.trim() === "") {
      promoInputError?.classList.add("hide");
    } else if (PromoCodeInput?.CouponCode === appliedCoupon) {
      promoInputError?.classList.add("hide");
    } else {
      promoInputError?.classList.remove("hide");
    }
  };

  return (
    <div>
      {countItems.length > 0 ? (
        <>
          <div className="cart__wrapper">
            <div className="page-width">
              <div className="cart_inner_wrap">
                <div className="cart__heading">
                  <h2>
                    My cart (<span>{countItems.length || 0}</span>)
                  </h2>
                </div>

                <div className="main__cart__container">
                  <div className="cart__container cart_left_sec">
                    <div className="cart-item">
                      <div
                        className="cart__item__contents"
                        ref={cartItemRefs.current[0]}
                      >
                        <CartItem
                          setCountItem={setCountItem}
                          countItems={countItems}
                          paymentType={paymentType}
                        />
                        <div className="cart_bottom_btns">
                          <div className="buy__now__btn">
                            <Link
                              to={"/cart"}
                              className="btn_primary buy__now__btn orange"
                            >
                              <img src={left_icon} />
                              Back To Cart
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cart__container cart_right_sec">
                    <div className="cart__discount__item">
                      <div
                        className="add_coupon_code"
                        onClick={toggleApplyCoupon}
                      >
                        <div className="apply_coupon_code">
                          <img src={coupon_icon} alt="coupon" />
                          <h6>Apply Coupon</h6>
                          <img className="cp_img_arrow" src={right_arrow_bk} />
                        </div>
                      </div>

                      {appliedCoupon && PromoCodeInput?.CouponCode && (
                        <div className="add_coupon_code applied_code">
                          <div className="apply_coupon_code">
                            <img src={green_check} alt="coupon" />
                            <h6>{appliedCoupon}</h6>
                            <span className="sup" onClick={handleRemoveCoupon}>
                              Remove
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="cart__final__total">
                      <div className="cart_top_item">
                        <div className="cart__amount__pay payble-ammount">
                          <div className="cart__final__price__heading ">
                            MRP Total
                          </div>
                          <div className="cart__final__price__amount">
                            {/* ₹{total.MRPTotal || 0} */}₹
                            {items.TotalMrp.toFixed(2) || 0}
                          </div>
                        </div>
                        <div className="cart__amount__pay service-fee">
                          <div className="cart__final__price__heading ">
                            Discount On MRP
                          </div>
                          <div className="cart__final__price__amount discount__price">
                            {/* -₹{total.DiscountOnMRP || 0} */}
                            -₹
                            {(items.TotalMrp - items.SubTotal).toFixed(2) || 0}
                          </div>
                        </div>
                        <div className="cart__amount__pay discount">
                          <div className="cart__final__price__heading ">
                            Offer Applied
                          </div>
                          <div className="cart__final__price__amount discount__price">
                            {/* -₹{total.totalDiscount || 0} */}
                            -₹{items.CouponDiscount.toFixed(2) || 0}
                          </div>
                        </div>
                        <div className="cart__amount__pay service-fee">
                          <div className="cart__final__price__heading ">
                            Shipping & Handling
                          </div>
                          <div className="cart__final__price__amount ">
                            {/* ₹{total.ShippingHandling || 0} */}₹
                            {items.DelhiveryCharges.toFixed(2) || 0}
                            {/* NEW PARAMETER */}
                          </div>
                        </div>
                        <div className="cart__amount__pay total">
                          <div className="cart__final__price__heading">
                            Total
                          </div>
                          <div className="cart__final__price__amount discount__price">
                            {/* ₹{total.finalAmoutAfterDiscount || 0} */}₹
                            {items.FinalPayableAmount.toFixed(2) || 0}
                          </div>
                        </div>
                      </div>
                      {total.totalDiscount > 0 && (
                        <div className="cart__amount__pay saved-amount">
                          <div className="save_img">
                            <img src={Imoji} alt="imoji" />
                          </div>

                          <div className="cart__save__amount">
                            You have saved ₹
                            {items.TotalMrp -
                              items.SubTotal +
                              items.CouponDiscount}{" "}
                            on this order
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="cart__final__container orderType">
                      <div className="orderTypeDiv">
                        <div className="payment_type">
                          <input
                            type="radio"
                            id="PrePaid"
                            name="orderType"
                            value="prePaid"
                            defaultChecked
                          />
                          <label htmlFor="PrePaid">PrePaid</label>
                        </div>

                        <img src={BankIcon} className="location_icon" />
                      </div>
                      <div className="orderTypeDiv">
                        <div className="payment_type">
                          <input
                            type="radio"
                            id="cod"
                            name="orderType"
                            value="cod"
                          />
                          <label htmlFor="cod">Cash On Delivery</label>
                        </div>

                        <img src={ShippingIcon} className="location_icon" />
                      </div>
                    </div>
                    <div className="cart__final__container">
                      {fetchUserData() ? (
                        displayAddresss && displayAddresss.pincode ? (
                          <div className="addressDisplay">
                            <div className="address_heading">
                              <h6>Delivered To</h6>
                              <button
                                onClick={handleAddress}
                                className="addressChangeButton "
                              >
                                Change
                              </button>
                            </div>
                            <div className="address_display">
                              <img
                                src={locationIcon}
                                className="location_icon"
                                alt="locationIcon"
                              />

                              <div>
                                {""}
                                {displayAddresss.houseNumberAndStreet.replace(
                                  "|",
                                  ","
                                )}{" "}
                                ,{displayAddresss.landmark} ,
                                {displayAddresss.city} , {displayAddresss.state}
                                -{displayAddresss.pincode} ,{" "}
                                {displayAddresss.country}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="addressDisplay">
                            <button
                              onClick={handleAddress}
                              className="addressDisplayButton "
                            >
                              <img src={addIcon} className="addIcon"></img>
                              <div>Add Address </div>
                            </button>
                          </div>
                        )
                      ) : (
                        <></>
                      )}

                      <div className="cart_total_main">
                        <div className="g_total_title">
                          Grand Total{" "}
                          <span>
                            ₹{items.FinalPayableAmount.toFixed(2) || 0}
                          </span>
                        </div>
                        <div className="cart_checkout_main">
                          <div
                            className={`submit__buttons btn_primary flex `}
                            onClick={!isCartEmpty ? checkoutBtn : null}
                            style={{
                              opacity: isCartEmpty ? 0.8 : 1,
                              cursor: isCartEmpty ? "not-allowed" : "pointer",
                            }}
                          >
                            Check Out
                            <img src={right_icon} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {PromoCodeDetails?.isOpen && (
                  <div className="popup_main">
                    <div
                      className="popup_overlay"
                      onClick={togglePromoCodePopUp}
                    ></div>
                    <div className="popup_wraper">
                      <div className="pop_up_title">
                        <h5>Coupon Details</h5>
                        <div
                          className="cs_pop_close"
                          onClick={togglePromoCodePopUp}
                        >
                          <img src={cross_black} />
                        </div>
                      </div>
                      <div className="popup_body_wrap">
                        <div className="coupon_code">
                          <div className="coupon_code_label">
                            <h5>{PromoCodeDetails?.Title}</h5>
                            <p>{PromoCodeDetails?.Description}</p>
                          </div>
                          <div className="coupon_code_tc">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: PromoCodeDetails?.TermsCondition,
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  id="myModal"
                  className="modal"
                  style={{ display: "flex", padding: 0 }}
                >
                  {displayLoginPages.displayAddressPage &&
                    displayLoginPages.accountVerified && (
                      <div className="modalWrapper">
                        <div className="modalContent">
                          <UpdateAddress
                            displayLoginPages={displayLoginPages}
                            setDisplayLoginPages={setDisplayLoginPages}
                            setAddress={setAddress}
                          />
                        </div>
                      </div>
                    )}
                </div>

                <div id="successfullModal" className="successfull_modal">
                  {confirmCODPopUp && fetchUserData() && (
                    <ConfirmCOD
                      setOTPVerified={setOTPVerified}
                      setconfirmCODPopUp={setconfirmCODPopUp}
                      setLoadingCOD={setLoadingCOD}
                      isLoadingCOD={isLoadingCOD}
                    />
                    //  <SuccessfullPop/>
                  )}
                </div>

                <div id="failedModal" className="successfull_modal">
                  {failedOrder && !confirmCODPopUp && (
                    <OrderFailedPopup setFailedOrder={setFailedOrder} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={`coupon_sidebar ${isApplyCoupon && "active"}`}>
            <div className="coupon_overlay" onClick={toggleApplyCoupon}></div>
            <div className="coupon_side_wrap">
              <div className="coupon_items">
                <div className="add_cop_name">
                  <h6>Apply Coupon</h6>
                  <div className="close_cp_bar" onClick={toggleApplyCoupon}>
                    <img src={cross_black} alt="svg icon" />
                  </div>
                </div>
                <div className="cart__discount__input flex">
                  {appliedCoupon ? (
                    <input
                      type="text"
                      placeholder="Add Coupon Code"
                      value={appliedCoupon}
                      onChange={handleCouponChange} // Add this to handle changes
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder="Add Coupon Code"
                      value="" // Provide an empty string as default
                      onChange={handleCouponChange} // Add this to handle changes
                    />
                  )}

                  <div className="cart__discount__apply__btn">
                    {appliedCoupon === PromoCodeInput?.CouponCode ? (
                      <span className="sup" onClick={handleRemoveCoupon}>
                        Remove
                      </span>
                    ) : (
                      <span onClick={discountApply}>Apply</span>
                    )}
                  </div>
                </div>
                {!PromoCodeInput?.CouponCode && (
                  <p className="promocode__input__error hide">Invalid Coupon</p>
                )}
              </div>

              <div className="coupon_code_inner">
                {PromoCode?.map((promo) => {
                  return (
                    <div key={promo.id} className="coupon_main_card">
                      <div
                        className="apply_btn"
                        onClick={() =>
                          handlePromoCodeAdd(promo.Title, promo.CouponCode)
                        }
                      >
                        <p>
                          {appliedCoupon === promo.CouponCode
                            ? "Applied"
                            : "Apply"}
                        </p>
                      </div>
                      <div className="coupon_img">
                        <img src={promo?.Image || off_01} alt="promo img" />
                      </div>
                      <div className="coupon_content">
                        <h6>{promo.Title}</h6>
                        <p>{promo.Description}</p>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: promo.TermsCondition,
                          }}
                        />
                      </div>
                      <span className="tm_cnt">
                        <a
                          role="button"
                          onClick={() => togglePromoCodePopUp(promo)}
                        >
                          *T &C apply
                        </a>
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="emptyCartDisplay">
          <div className="emptyCart_container">
            <img
              className="emptyCartImage "
              src={CartImage}
              alt="Empty Cart Image"
            />
            <div className="cart_empty_cart_wrap">
              <h5>Your cart is empty</h5>
              <p>
                Looks like you have not added anythink to your cart. Go ahead &
                explore top categories.
              </p>
            </div>
            <div className="startShopping_contanier">
              <Link to={"/"} className="btn_primary">
                Start Shopping
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckOutPage;
