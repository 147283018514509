import Cookies from "js-cookie";
import { fetchRegisterId, WEB_PLACE_ORDER } from "../../../Constants";


const placeOrder = async ({ items, total, navigate, userDataObj,setLoadingCOD, displayAddresss, setconfirmCODPopUp, setFailedOrder, setOrder }) => {
  let resigterId = fetchRegisterId();


//console.log(items)

  let failedmodal = document.getElementById("failedModal");
 // console.log(failedmodal);
  try {
    // Generate OrderId dynamically or fetch from another service
    const orderId = `ORD-${Date.now()}`; // Example of generating a unique OrderId based on timestamp

    // Define the order data
    const orderData = {
      RegisterId: resigterId,
      OrderId: orderId,
      DelhiveryCharges : items.DelhiveryCharges,
      PromoCode: items.CouponDiscount, // You can dynamically assign this if applicable
      SubTotal: items.SubTotal || 0,
      arrayTotalDiscountPrice: items.FinalPayableAmount || 0,
      TotalPayableAmount: items.FinalPayableAmount || 0,
      TransactionId: "", // Should be dynamically fetched or provided if needed
      Address: `${displayAddresss.houseNumberAndStreet},${displayAddresss.landmark},${displayAddresss.city},${displayAddresss.state},${displayAddresss.country}-${displayAddresss.pincode}.`, // Replace with actual data if available  ,
      AddressId: "", // Should be dynamically fetched or provided
      CurrentLat: '', // Replace with actual latitude if available
      CurrentLng: '', // Replace with actual longitude if available
      CouponDiscount: items.CouponDiscount, // Example value; replace with actual discount if applicable
      WalletAmount: "", // Example value; replace with actual wallet balance if applicable
      PaymentType: "COD",
      Country: "India",
      UsedWalletBalance: "", // Example value; replace with actual wallet balance if applicable
      Product: JSON.stringify(items.Result.map(item => ({
        ProductId: item.ProductId || "unknown", // Replace with actual ProductId
        FinalPriceWithQty: item.FinalPriceWithQty || 0,
        FinalDiscountPriceWithQty: item.FinalDiscountPriceWithQty || 0,
        productVariantId: item.productVariantId || "unknown"
      })))
    };

    // Send request to the API
    const response = await fetch(WEB_PLACE_ORDER, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(orderData),
    });

    // Handle API response
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to place order! Status: ${response.status}, Message: ${errorText}`);
    }

    const result = await response.json();
   // console.log(result)
    setOrder(result.Status);
    // Redirect to the order confirmation page
    // navigate("/order-confirmed", { state: { orderDetails: result } });
    if(result.Status)
    {
      navigate('/thankyou');
    }else{
      console.log("Failed");
      setFailedOrder(true);
    }
    
   
setLoadingCOD(false);

  } catch (error) { 
    console.error('Error placing order:', error.message);
  }

};

export default placeOrder;
