import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageComponent from '../../image/mainImageComp';
import Loader from '../../loader/loadingCard';

// import { PRODUCTDETAIL } from "../../../../Constants";
import { useMediaQuery } from 'react-responsive';
import { fetchBannerData } from '../../../../api/HomePageApi';
// import { ReactComponent as LeftArrow } from '../../../../assests/icons/LeftArrow.svg';
import { Link } from "react-router-dom";

const DefaultSliderImages = [
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 1',
    productId: '',
    Variant1Value: '',
    Variant2Value: ''
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 2',
    productId: '',
    Variant1Value: '',
    Variant2Value: ''
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 3',
    productId: '',
    Variant1Value: '',
    Variant2Value: ''
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 4',
    productId: '',
    Variant1Value: '',
    Variant2Value: ''
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 5',
    productId: '',
    Variant1Value: '',
    Variant2Value: ''
  }
];
let banners;
const OfferBannerCard = () => {
  const [sliderImages, setSliderImages] = useState(DefaultSliderImages);
  const [isLoading, setIsLoading] = useState(true);
  //const [dailyDeals, setDailyDeals] = useState([]);

  useEffect(() => {
    let isMounted = true; // track if component is mounted
    const loadBannerData = async () => {
      try {
        const data = await fetchBannerData();
         banners = data?.Advertisement?.DailyDeals || [];
       // setDailyDeals(data);
        if (banners.length > 0) {
          const formattedImages = banners.map(banner => ({
            mobileSrc: banner.Banner?.trim() || '/images/default-mobile.png',
            desktopSrc: banner.Banner?.trim() || '/images/default-desktop.png',
            productId: banner.ProductId || "",
            Variant1Value: banner.Variant1Value || "",
            Variant2Value: banner.Variant2Value || "",
            alt: banner.alt || 'Default Image'
          }));
          if (isMounted) {
            setSliderImages(formattedImages);
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.error('Error fetching banner data:', error);
        if (isMounted) {
          setSliderImages(DefaultSliderImages);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    loadBannerData();

    return () => {
      isMounted = false; // cleanup function to set isMounted to false when the component unmounts
    };
  }, []); // empty dependency array to run only once on mount


  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const sliderSettings = {
    dots: false,
    arrows: true,
    rtl: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.45,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    touchThreshold: 100,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.45,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.45,
          slidesToScroll: 1,
          dots:false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots:true
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        }
      }
    ]
  };

  return (
    <div> {banners &&  (
      <div className="offer__banner__wrapper section_space">
      <div className="offer__banner__container amazing__brand page-width">
        <div className="hm__prod__slider-title less_space_title">
          <h2>Deals of the day</h2>
          {/* <div className="hm__prod__mobile__arrow"><LeftArrow /></div> */}
        </div>
      </div>
      <div className="banner_sl_container">
        {isLoading ? (
          <div className="loading-spinner">
            <Loader />
          </div>
        ) : (
          <Slider {...sliderSettings} className="cm_banner_slider cm_arrow_side">
            {sliderImages.map((image, index) => (
              <div key={index}>
                {image.productId && (
                  <Link to={`/product?type=productvariant&productid=${image.productId}&variant1Value=${image.Variant1Value
                    ?.replace(/\s+/g, '+')
                    }&variant2Value=${image.Variant2Value
                    // ?.replace(/\s+/g, '')
                    ?.replace(/\s+/g, '+')
                    }`}>

                    <ImageComponent
                      src={isMobile ? image.mobileSrc : image.desktopSrc}
                      alt={image.alt}
                      height={'320px'}
                      pageWidth={isMobile ? "100%" : "1200px"}
                    />
                  </Link>
                )}
              </div>
            ))}
          </Slider>
        )}


      </div>
    </div>)}</div>
   
   
  );
}

export default OfferBannerCard;
