import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import CategoryFilter from "./categoryFilter";
import BrandBanner from "./categoryBanner";
import BrandMetaTag from "../../layout/metaTag/metaTagComp";

import {
  PRODUCTLIST_BY_CATEGORY,
  WEBPRODUCTLIST,
  PRODUCTLIST,
  PARENT_CATEGORY_AND_SUBCATEGORYLIST,
  FILTER_LIST,
} from "../../../Constants";

const CategoryDetail = ({ setCountItems }) => {
  const [subCategories, setSubCategoriesData] = useState([]);
  const [filterListData, setfilterListData] = useState({
    Brands: [],
    Categories: [],
    Flavours: [],
  });
  const [appliedFilters, setAppliedFilters] = useState({
    Brands: [],
    Flavours: [],
    Price: [],
    Discount: [],
  });
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [productLength, setProdLength] = useState([]);
  const [selectedData, setSelectedData] = useState({
    categoryName: "",
    categoryId: "",
    categoryImage: "",
    subCategory: "",
    subDetails: "",
    id: "",
  });
  const [doesAPIHaveFilter, setDoesAPIHaveFilter] = useState(false)
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const location = useLocation();
  const hasFetched = useRef(false); // Ref to track if fetch has been done


  const params = new URLSearchParams(location.search);
  const idFromUrl = params.get('id');
    const categoryId = idFromUrl;
    const viewidFromUrl = params.get('viewId');
    const viewId = viewidFromUrl;

  const fetchBrands = async () => {
    const apiSubUrl = PARENT_CATEGORY_AND_SUBCATEGORYLIST;
    let payload = {
      groupCategoryId: 0,
      LevelType: "shopDashboard",
    };
    try {
      const subNavResponse = await fetch(apiSubUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(payload).toString(),
      });

      if (!subNavResponse.ok) {
        throw new Error(`HTTP error! status: ${subNavResponse.status}`);
      }

      const fetchedData = await subNavResponse.json();
      setSubCategoriesData(fetchedData.ParentCategory);
      //console.log(fetchedData.ParentCategory)
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };
  const fetchFilters = async () => {
    const apiUrl = FILTER_LIST;
    try {
      const filterListResponse = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (!filterListResponse.ok) {
        throw new Error(`HTTP error! status: ${filterListResponse.status}`);
      }

      const fetchedData = await filterListResponse.json();
      //console.log(fetchedData.ParentCategory)
      setfilterListData({
        Brands: fetchedData?.Brands,
        Categories: fetchedData?.Categories,
        Flavours: fetchedData?.Flavours,
      });
    } catch (error) {
      console.error("Error fetching brands:", error);
    }finally{
      setTimeout(() => {
        
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchCategoryFromUrl = (locationHref, categories) => {
    if (!locationHref || categories.length === 0) return;
    
    let urlBreak = locationHref.split("/");
    
    let categoryDetails = "";
    let subCategoryDetails = "";
    
    if (urlBreak.length > 4) {
      categoryDetails = categories.find(
        (cat) =>
          encodeURIComponent(cat.parent_category_name) === urlBreak[4]
      );
      subCategoryDetails =
      categoryDetails &&
      categoryDetails.SubCategory.find(
        (sub) =>
          encodeURIComponent(sub.category_name) === urlBreak[5]
      );
    } else {
      subCategoryDetails = categories.find(
        (cat) =>
          encodeURIComponent(cat.parent_category_name) === urlBreak[4]
      );
    }
    // let id = categories.filter((category) => )
    setSelectedData({
      categoryName: urlBreak[4],
      categoryId: categoryDetails?.id || "",
      categoryImage: categoryDetails && categoryDetails.CategoryImage,
      subCategory: urlBreak[5] ? urlBreak[5] : "",
      subDetails: subCategoryDetails,
      id: subCategoryDetails ? subCategoryDetails.id : "",
    });
  };
  const reverseTransform = (str) => {
    let reversed = str.replace(/-/g, " ");
    reversed = reversed
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return reversed;
  };

  const fetchProductList = async (listType, page) => {

    let parentCategoryName = location.pathname.split('/')[2]?.replace(/-/g, ' ');
    let parentCategory = decodeURIComponent(parentCategoryName);
    // console.log("selectedDataselectedDataselectedData",parentCategoryName)

    let apiUrl = ``;
    if (listType) {
      apiUrl = "";
    } else {
      apiUrl = WEBPRODUCTLIST;
    }
    let payload;
    if (listType) {
      payload = {
        CategoryId: selectedData.categoryId || viewId,
        Pagination: page || "1",
        ParentCategoryName: parentCategory,
        LevelType: "groupCategory",
        groupCategoryId: ""
      };
    } else {
      const originalCategoryName = reverseTransform(selectedData.categoryName);

      payload = {
        Categoryid: selectedData.categoryId || viewId,
        SubCategoryId:"", // CategoryName: selectedData.categoryName,
        Pagination: page || "1",
        ParentCategoryName: parentCategory,
        LevelType: "groupCategory",
        groupCategoryId: ""

      };
    }
    try {
      const navResponse = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(payload).toString(),
      });

      if (!navResponse.ok) {
        throw new Error(`HTTP error! status: ${navResponse.status}`);
      }
      const categoryData = await navResponse.json();
      // console.log('categoryData', categoryData)

      // setProductList((prev) => [...prev, categoryData?.Result]);
      // setVisibleProducts((prev) => [...prev, categoryData?.Result]);
      setProductList((prev) => {
        return [[...prev,...categoryData?.Result].flat()]
      });
      setVisibleProducts((prev) => ([[...prev,...categoryData?.Result].flat()]));

      // setVisibleProducts(categoryData?.Result);
      setProdLength(categoryData.Result.length);
      setHasMore(categoryData.Result?.[0]?.length === 10); // Check if more products are available
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
  const fetchProductList2 = async (listType, page) => { 

    let parentCategoryName = location.pathname.split('/')[2];
    let parentCategory = decodeURIComponent(parentCategoryName);

    let apiUrl = ``;
    // if (listType) {
    //   apiUrl = PRODUCTLIST;
    // } else {
    //   apiUrl = PRODUCTLIST;
    // }

    apiUrl = PRODUCTLIST;
    let payload;
    if (listType) {
      payload = {
        SubCategoryid: selectedData.id || categoryId | "",
        Pagination: page || "1",
        ParentCategoryName: parentCategory.replace(/\+/g, ' ').replace(/%20/g, ' '),
        LevelType: "groupCategory",
        groupCategoryId: ""
      };
      setDoesAPIHaveFilter(false)
    } else {
      setDoesAPIHaveFilter(true)
      payload = {
        // Categoryid: selectedData?.subDetails?.id || selectedData.categoryId,
        SubCategoryid: selectedData.categoryId || categoryId || "",
        ParentCategoryName: parentCategory.replace(/\+/g, ' ').replace(/%20/g, ' '),
        groupCategoryId: "",
        Pagination: page || "1",
        ...appliedFilters,
      };
    }
    try {
      const navResponse = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(payload).toString(),
      });

      if (!navResponse.ok) {
        throw new Error(`HTTP error! status: ${navResponse.status}`);
      }
      const categoryData = await navResponse.json();
      setProductList(categoryData?.Result);
      setVisibleProducts(categoryData?.Result);
      setProdLength(categoryData.Result.length);

      if(listType){
        setProductList(categoryData?.Result);
        setVisibleProducts(categoryData?.Result);
        setProdLength(categoryData.Result.length);
      }else{

        setProductList((prev) => {
          return [[...prev,...categoryData?.Result].flat()]
        });
        setVisibleProducts((prev) => ([[...prev,...categoryData?.Result].flat()]));
      }
       setHasMore(categoryData.Result?.[0]?.length === 10); 
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {

    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchBrands(), fetchFilters()]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setTimeout(() => {
          
          setLoading(false);
        }, 1000);
      }
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    setPage(1);
    hasFetched.current = false; 

    if (subCategories.length > 0 && location.pathname) {
      fetchCategoryFromUrl(window.location.href, subCategories);
    }
  }, [subCategories, location.pathname]);

  useEffect(() => {
    if (selectedData.id || selectedData.categoryName) {
    }
  }, [selectedData, page, appliedFilters]);
  useEffect(() => {
  }, [productList]);


  const hasViewId = params.has('viewId');


  useEffect(() => {
    const path = location.pathname;
    console.log('pag222e', page)
    if(!hasViewId){
      fetchProductList2();
    }
    
    const hasId = params.has('id');
    
    if(!hasId){
      fetchProductList("",page);
     }

  }, [location,page]);
  // console.log('page', page)

  const loadMoreProducts = () => {
    // console.log('hasMore', hasMore)
    // console.log('hasFetched.current', hasFetched.current)
    if (hasMore && !hasFetched.current) {
      // hasFetched.current=true
      setPage((prev) => prev + 1);
    }
  };


  const handleFilterInputChange = (event, type) => {
    const { id, name, checked } = event.target;

    setAppliedFilters((prev) => {
      const updatedFilters = { ...prev };
      const key = type;
      updatedFilters[key] = checked
        ? [...prev[key], id]
        : prev[key].filter((cur) => cur !== id);
      return updatedFilters;
    });
  };
  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // console.log(" ");
            loadMoreProducts();
          } else {
            console.log("Product not visible");
          }
        });
      },
      { threshold: 0 }
    );

    const observeLastProductCard = () => {
      const productCards = document.querySelectorAll(
        ".brand__product-grid .product-card"
      );
      // console.log("productCards", productCards);
      setProdLength(productLength);
      if (productCards.length === 0) return;
      const lastProductCard = productCards[productCards.length - 1];
      // console.log(lastProductCard,"lastProductCard")
      intersectionObserver.observe(lastProductCard);
    };

    observeLastProductCard();

    return () => {
      intersectionObserver.disconnect();
    };
  }, [page,loading]); // Include loading in dependencies
  // console.log('page', page)
  return (
    <>
    <BrandMetaTag />
    <div className="brand-detail">
      {/* <BrandBanner categoryImage={selectedData.categoryImage} /> */}
      <div className="brand__detail__breadcrumb page-width">
        {/* <Breadcrumb /> */}
      </div>
      <div className="brand__details__filter">
        <CategoryFilter
          categories={subCategories}
          productList={visibleProducts}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          setCountItems={setCountItems}
          productLength={productLength}
          filterListData={filterListData}
          handleFilterInputChange={handleFilterInputChange}
          loading={loading}
          doesAPIHaveFilter={doesAPIHaveFilter}
        />
      </div>
      {/* {hasMore && (
        <div className="loadmore__btn">
          <button onClick={loadMoreProducts}>Load More</button>
        </div>
      )} */}
    </div>
    </>
  );
};

export default CategoryDetail;
