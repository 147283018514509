import React, { useEffect, useState } from 'react'
import { ADD_TO_CART, fetchRegisterId, WEB_CARTLIST } from '../../../Constants';
import { getCookie } from '../../../utils/cookieUtils';
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';



function AddToCartList(){
//const [cartItems,setCartItems] = useState([]);
    


///const navigate = useNavigate();

    // console.log("In addToCartList");
        const addToCart = async (product) => {
            let registerID;
             if (fetchRegisterId()) { // Check if user is logged in
                registerID =fetchRegisterId();}
           
      // console.log(registerID);
              try {
                const response = await fetch(ADD_TO_CART, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    ProductId: product.productId,
                    RegisterId :registerID,
                    ProductQuantity:product.quantity,
                    variant1_value: product.variant1Value?.replace(/\+/g, " ") ,
                    variant2_value: product.variant2Value?.replace(/\+/g, " ") ,
                  }),
                });
        
                if (!response.ok) {
                  throw new Error(`Failed to add product to cart! Status: ${response.status}`);
                }
        
                const result = await response.json();
                // console.log(result)
              

                    Cookies.remove("cartData");
                    
                    if(result.Status){
                      //  console.log("reloading")
                        window.location.reload();
       
                    }
                    // navigate('/cart');
               
              
              } catch (error) {
                console.error('Error adding product to cart:', error);
                // Notify user of error
              
              }
            

      

     

     
    }
    // console.log("useEffect");
   
    let existingCartData = getCookie('cartData');
    //setCartItems(existingCartData);
    if(existingCartData){
       existingCartData = JSON.parse(existingCartData);
    
    existingCartData.map((product) => {
        addToCart(product) 
    })
  }
    
    return (
        <div>
          
        </div>
      )
}
export default AddToCartList;




