import React, { useEffect, useRef, useState } from "react";
import "./orderHistoryItem.css";
import OrderStatusItem from "./orderStatusItem";
import locationIcon from "../../../../assests/image/location.png";
import phoneIcon from "../../../../assests/icons/Phone.svg";
import Paper from "../../../../assests/icons/Paper.svg";
import PopUp from "./popUp";
import "./trackOrder.css";
import Cookies from 'js-cookie';
import { fetchRegisterId,PDF_GENERATE, ORDER_DETAIL, WEB_ADDRESS } from "../../../../Constants";
import CustomLoader from "../../../common/customLoader/customLoader";
const orderStatusNo = [ "pending", "packed", "package_shipped", "out_for_delivery", "delivered","cancelled"];


const OrderStatus = ({ orderID_details }) => {
  const url = new URL(window.location.href);
  let orderId = url.searchParams.get("id");
 
  // const ORDER_DETAIL = `https://${admin}/api/OrderDetail`;
  //console.log(orderIds.split('+'));
  //  let orderId = orderIds.split('+');
  //console.log(orderId);

  const [orderDetails, setOrderDetails] = useState([]);
  const [orderAmountDetails, setOrderAmountDetails] = useState([]);

  const [confirmPopUp, setConfirmPopUp] = useState(false);
  const [returnId, setReturnId] = useState();
const [loading,setLoading] = useState(false);
  const [orderStatusNumber, setOrderStatusNumber] = useState(0);

  const fetchOrderStatus = async () => {
    try {

      const registerID = fetchRegisterId();  //  Cookies.get("userData");
      const response = await fetch(ORDER_DETAIL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          RegisterId: registerID,
          OrderId: orderId,
        }),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch cart items! Status: ${response.status}`
        );
      }

      const orderDetails = await response.json();

      setOrderDetails(orderDetails.Result);
      setOrderAmountDetails(orderDetails);
      setLoading(false)
    } catch (error) {
      console.log(error.message);
    }
  };
  let userData = JSON.parse(Cookies.get("userData"));
  useEffect(() => {
    setLoading(true)
   // console.log(orderDetails)
    fetchOrderStatus();
  }, []);

 
  const handleCancelOrder = () => {
    setConfirmPopUp(true);
  };
  const handleReturnOrder = () => {
      setConfirmPopUp(true);
      setReturnId(orderId)
    
   
  }

  useEffect(() => {
    if(orderDetails[0]){
      let orderStatusN = orderStatusNo.findIndex(status => status === orderDetails[0]?.OrderStatus.toLowerCase());
      setOrderStatusNumber(orderStatusN)
    }
   
  }, [orderDetails])


  const handleDownload = () => {
    const fileUrl = `${PDF_GENERATE}/${orderId}`; // URL of the invoice file
    const link = document.createElement('a');
    link.href = fileUrl;
    link.click();

  };

if(loading){
  return (
    <CustomLoader/>
  )

}else{
  return (
    <div className="orderStatusWrapper">
      <div className="order__status__final page-width">
        <div className="productsWrapper ">
          {/* <Link to={"/account/orders"} className="returnToOrderHistory">
            <img src={Arrow} className="location__icon turn180" />
            <h6>Back To Order</h6>
          </Link> */}
          <div className="product_status_txt">
            <h5>Order Status</h5>
          </div>
          <div className="trackingWrapper">
            <div className="trackingBox">
              <div className="text">Order Confirmed</div>
              <div className={`order-${orderStatusNumber > 0 ? 'active' : 'inactive'}`} />

            </div>
            { orderStatusNumber !==5 && <div className="trackingBox">
              {" "}
              <div className="text">Shipped</div>
              <div className={`order-${orderStatusNumber > 1 ? 'active' : 'inactive'}`} />

            </div> }
            {  orderStatusNumber !==5 && <div className="trackingBox">
              {" "}
              <div className="text">Out for delivery</div>
              <div className={`order-${orderStatusNumber > 2 ? 'active' : 'inactive'}`} />

            </div>}
            
            <div className="trackingBox">
              {" "}
              <div className={` ${orderStatusNumber === 5 ? 'red' : 'text'}`}>{orderStatusNumber === 5 ? 'Cancelled' :'Delivered'  }</div>
              <div className={`delivered order-${orderStatusNumber === 4 ? 'active' : orderStatusNumber === 5 ? ' dot':'inactive'}`} />

            </div>
          </div>
          <div className="product_title_order">
            <h5>Products</h5>
            {orderStatusNumber === 4 && <button className="invoice_btn" onClick={handleDownload}>
              <img src={Paper} className="location__icon" />
              Invoice
            </button>}
            {/*  */}
          </div>
          <div className="productsDisplayContainer">
            <div className="leftContainer">
              {orderDetails &&
                orderDetails.map((orderDetail) => (
                  <OrderStatusItem
                    orderDetail={orderDetail}
                    key={orderDetail.Id}
                  />
                ))}
            </div>
            <div className="rightContainer">
              <div className="rightContainer_wrapper ">
                <div className="info">
                  {" "}
                  <div className=" text"> Order Status</div>
                  <div
                    className={`idText ${orderDetails[0] &&
                      orderDetails[0].OrderStatus === "Cancelled"
                      ? "cancelled"
                      : orderDetails[0] &&
                        orderDetails[0].OrderStatus === "Pending"
                        ? "pending"
                        : "completed"
                      }`}
                  >
                    {orderDetails[0] && orderDetails[0].OrderStatus.toUpperCase().replace(/_/g, ' ')}
                  </div>
                </div>
                <div className="info">
                  {" "}
                  <div className=" text"> Order Id</div>
                  <div className="actual_price">#{orderId}</div>
                </div>
              </div>
              <div className="rightContainer_wrapper ">
                <div className="actual_price">Delivered To</div>
                <div className=" addressInfo">
                  {" "}
                  <img
                    src={locationIcon}
                    className="location__icon"
                    alt="locationIcon"
                  />

                  <div className="location_info">{orderDetails[0] && orderDetails[0].Address}</div>
                </div>
                <div className=" addressInfo">
                  {" "}
                  <img src={phoneIcon} className="location__icon" alt="Phone" />
                  <div className="location_info">+91 {userData.phoneNumber || userData.MobileNo}</div>

                </div>
              </div>
              <div className="rightContainer_wrapper text">
                <div className="info">
                  {" "}
                  <div className=" text"> Amount to pay</div>
                  <div className="actual_price">
                    ₹{orderAmountDetails.SubTotalWithTax}
                  </div>
                </div>
                <div className="info">
                  {" "}
                  <div className=" text"> Discount</div>
                  <div className="discount_price">
                    -₹{orderAmountDetails.Discount}
                  </div>
                </div>
                <div className="dot__line"></div>
                <div className="info">
                  {" "}
                  <div className="actual_price"> Total</div>
                  <div className="discount_price">
                    ₹{orderAmountDetails.TotalPayableAmount}
                  </div>
                </div>
              </div>
              {orderAmountDetails.CancelOrder === "Yes" && orderDetails[0] && orderDetails[0].OrderStatus === "Pending" && (
                <div className="cancel_wrapper">
                  <button
                    className="btn_primary"
                    onClick={handleCancelOrder}
                  >
                    Cancel Order
                  </button>
                </div>
              )}
              { orderAmountDetails.TrackOrder && (
                <a className="cancel_wrapper" target="_blank" href={orderAmountDetails.TrackOrder}>
                <button
                  className="btn_primary"
                  
                >
                 Track Order
                </button>
              </a>
              )
                
              }
              {orderAmountDetails.Returnable === "No" && (
                <div className="cancel_wrapper">
                  <button
                    className="btn_primary"
                    onClick={handleReturnOrder}
                  >
                    Return Order
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div id="successfullModal" className="successfull_modal">
        {confirmPopUp && orderId && (
          <PopUp setConfirmPopUp={setConfirmPopUp} orderId={orderId} returnId={false} />
          //  <SuccessfullPop/>
        )}
        {confirmPopUp && returnId && (
          <PopUp setConfirmPopUp={setConfirmPopUp} returnId={returnId} />
          //  <SuccessfullPop/>
        )}
      </div>
    </div>
  );
};
}


export default OrderStatus;
