import React, { useState } from 'react'; // Import useState
import { Link } from 'react-router-dom';
import MegaMenu from '../megaMenu/megaMenu';
import './nav.css';
import { STAGE_ADMIN } from '../../../../Constants';

const Navigation = ({ setDisplayProductList }) => {
  const [isMegaMenuActive, setMegaMenuActive] = useState(false);

  // Functions to handle hover state
  const handleMouseEnter = () => {
    setMegaMenuActive(true);
  };

  const handleMouseLeave = () => {
    setMegaMenuActive(false);
  };

  return (
    <nav className="main__header__wrapper navbar">
      <ul className="brand__menu">
        {/* Single menu item with hover functionality */}
        <li
          className="mega-menu-item"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Link to="/">Brands</Link>
          {/* Pass the active prop based on hover state */}
          <MegaMenu active={isMegaMenuActive} />
        </li>

        {/* Additional menu items */}
        {STAGE_ADMIN && (
          <li className="mega-menu-item">
            <Link to="/blogs/blog-posts">Blogs</Link>
          </li>
        )}
        {/* Uncomment and add more links as needed */}
        {/* <li><Link to="/service">Unfit Store</Link></li> */}
      </ul>
    </nav>
  );
};

export default Navigation;
