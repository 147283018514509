import "./popUp.css";
import OrderUnsuccessfull from "../../../../assests/image/OrderUnsuccessfull.png";
//import SuccessfullTick from "../../../../assests/icons/succesfullTick.png";
import { Link } from "react-router-dom";

const OrderFailedPopup = () => {

 
  console.log("Failed here")

  return (
    <div className="successfull-modalWrapper">
      <div className="successfull-modalContent">
    <img src={OrderUnsuccessfull} className="imageUnsuccessfull" alt="logo"/>

       
        
          <Link to={"/cart"} className="back-to-cart">
            {" "}
            <button className="back-to-cart">Return to Cart</button>
          </Link>{" "}

      
      </div>
    </div>
  );
};
export default OrderFailedPopup;
///order-status/order?id=${orderList}
