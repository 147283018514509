import React, { useState } from "react";
import "../../layout/product/productCard/productCard.css";
import { Link, useNavigate } from "react-router-dom";
import CartIcon from "../../../assests/icons/cartIcon.svg";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Close_Square } from "../../../utils/image";
import Select from "react-select";
import {
  fetchRegisterId,
  replaceReplacementChar,
  ADD_TO_CART,
} from "../../../Constants";
import addProductToCookies from "../../layout/cart/addProductToCookies";
import fetchProductQuantityInCartList from "../../../api/fetchProductQuantityInCartList";

const API_URL = ADD_TO_CART;

const SearchCard = ({
  product,
  setCountItems,
  onAddtoCart,
  currentSelected = [],
  isOutOfStock = false,
}) => {
  // console.log("product", product.VariantData && product?.VariantData[0].Note  );
  const VariantData = product.VariantData;
  const getUniqueVariant2Values = (data) => {
    const uniqueVartants = data?.reduce((acc, cur) => {
      const existingVariant = acc.find(
        (item) => item.Variant2Value === cur.Variant2Value
      );
      if (existingVariant) {
        existingVariant.Variant1Values.push(cur.Variant1Value);
      } else {
        acc.push({
          Variant2Value: cur.Variant2Value,
          Variant1Values: [cur.Variant1Value],
        });
      }
      return acc;
    }, []);
    return uniqueVartants;
  };
  const uniqueVariantDetails = getUniqueVariant2Values(VariantData);
  const requiredCardData = uniqueVariantDetails?.map((value) => {
    if (value.Variant2Value) {
      return {
        weight: value.Variant2Value,
        options: value?.Variant1Values?.map((valuee) => ({
          label: valuee,
          value: valuee,
        })),
      };
    }
  });
  const [variant1Value, setVariant1Value] = useState({});
  const [variant2Value, setVariant2Value] = useState("");
  const addToCart = async () => {
    // setCountItems((prev) => prev + 1)
    try {
      let RegisterId = fetchRegisterId();

      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ProductId: product.id,
          RegisterId,
          ProductQuantity: 1,
          variant1_value: variant1Value?.value,
          variant2_value: variant2Value, // Adjust as needed, depending on how quantity is managed
        }),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to add product to cart! Status: ${response.status}`
        );
      }

      //   dispatch({
      //     type: 'ADD_TO_CART',
      //     payload: {
      //       id: product.id,
      //       title: product.name,
      //       price: product.price,
      //       quantity: 1,
      //       image: product.image,
      //     },
      //   });

      toast.success("Product successfully added to cart!");
          setCountItems((prev) => prev + 1)

    } catch (error) {
      console.error("Error adding product to cart:", error);
      toast.error("Error adding product to cart");
    } finally {
      onAddtoCart({ id: product.id });
    }
  };



  const pageWidth = 1200;


  let brandId = product.id;

  const handleVariant1Change = (option) => {
    setVariant1Value(option);
  };

  const handleVariant2Change = (value) => {
    setVariant2Value(value);
    // setVariant1Value("");
  };
  const submitAddToCart = async () => {
    // console.log("from home page ");
    if (!variant2Value?.length) {
      toast.error("Please select Size");
    } else if (!variant1Value?.value?.length) {
      toast.error("Please select flavour");
    } else {
      if (fetchRegisterId()) {
        let result = await fetchProductQuantityInCartList(
          product.id,
          variant1Value.value,
          variant2Value
        );
        // console.log(result);
        if (result) {
          // console.log("adding ");
          addToCart();
        }
      } else {
        // console.log("requiredVariantData", product);
        const requiredVariantData = product.VariantData.filter(
          (res) => res.Variant1Value === variant1Value.value
        ).filter((rest) => rest.Variant2Value === variant2Value)?.[0];
        // console.log("requiredVariantData", requiredVariantData);
        if (
          addProductToCookies(
            product.Name,
            product.id,
            1,
            requiredVariantData?.Price || 0,
            requiredVariantData?.Mrp || 0,
            requiredVariantData?.Note || "",
            requiredVariantData?.pvid || null,
            variant1Value.value,
            variant2Value,
            product.Image
          )
        ) {
          onAddtoCart({ id: product.id });
          toast.success("Product successfully added to cart!");
              setCountItems((prev) => prev + 1)
        }
      }
    }
  };



  return (
    <div className={`product-card ${isOutOfStock && "product_not_available"}`}>
      <Link
        to={`/product?type=productvariant&productid=${product.id}&variant1Value=${product.Variant1Value}&variant2Value=${product.Variant2Value}`}
      >
        {isOutOfStock && (
          <div className="not_available_wrap">
            <div className="sold_out_label">
              <h2>Out Of Stock</h2>
            </div>
          </div>
        )}
      </Link>
      <div className="card-wrapper">
        <div className="card-item" data={brandId}>
          <Link
            to={`/product?type=productvariant&productid=${product.id}&variant1Value=${product.Variant1Value}&variant2Value=${product.Variant2Value}`}
          >
            {/* <Link to={`/product/${product.ShortURL || productUrl || ""}/${product.id}/${product.Variant1Value}/${product.Variant2Value}`} > */}
            <div className="card-image">
              <img
                sizes={`
                  (min-width: ${pageWidth}px) ${Math.floor(
                  (pageWidth - 130) / 4
                )}px,
                  (min-width: 990px) calc((100vw - 130px) / 4),
                  (min-width: 750px) calc((100vw - 120px) / 3),
                  calc((100vw - 35px) / 2)
                `}
                src={product.Image}
                alt={product.title}
                className="motion-reduce"
                loading="lazy"
                width={product.width || 533}
                height={product.height || 400}
              />
             { product?.Note ?
                    (<div className="product__discount">
                      <span>{(product?.Note).replace("(","").replace(")","")}</span>
                    </div>) :
                  product.VariantData && product?.VariantData[0].Note && (
                    <div className="product__discount ">
                      <span>{ product?.VariantData[0].Note.replace("(","").replace(")","")}</span>
                    </div>
                  )
                  }
            </div>
          </Link>
          <div className="card-content">
            <Link
              to={`/product?type=productvariant&productid=${product.id}&variant1Value=${product.Variant1Value}&variant2Value=${product.Variant2Value}`}
            >
              <div className="product_title">
                <h2>{replaceReplacementChar(product.name)}</h2>
              </div>

              <div className="product_price">
                <div className="price">
                  <div className="actual_price">₹{product.Price}</div>
                  <div className="compare_price">
                    <s>₹{product.MRP}</s>
                  </div>
                  { product?.Note ? 
                  <div className="product__discounts green">
                  <span>{ product?.Note}</span>
                </div>
                  : product.VariantData && product?.VariantData[0].Note && (
                    <div className="product__discounts green">
                      <span>{ product?.VariantData[0].Note}</span>
                    </div>
                  )}
                </div>
              </div>
            </Link>
            <div className="product_btns">
              <div
                className="product_atc_btn"
                onClick={() => {
                  if (onAddtoCart) {
                    onAddtoCart(product);
                  } else {
                    addToCart();
                  }
                }}
              >
                <img src={CartIcon} alt="icon icon-cart" />
                <div className="btn_text">Add To Cart</div>
              </div>
            </div>
          </div>

          <div
            className={`card_variant_main ${
              currentSelected?.includes(product.id) ? "active_var" : ""
            }`}
          >
            <div className="card_var_title">
              <h6>Select Size</h6>
              <div
                className="close_btn cursor-pointer"
                onClick={() => onAddtoCart(product)}
              >
                <img src={Close_Square} alt="close" />
              </div>
            </div>
            <div className="card_var_body">
              {requiredCardData?.map((variant) => {
                return (
                  <div
                    className="card_var_items"
                    key={variant?.weight}
                    onClick={() => handleVariant2Change(variant?.weight)}
                    style={{
                      border:
                        variant?.weight === variant2Value
                          ? "2px solid orange"
                          : "",
                    }}
                  >
                    <h5>{variant?.weight}</h5>
                    <div className="card_var_select">
                      <Select
                        options={variant?.options}
                        value={variant1Value}
                        onChange={handleVariant1Change}
                        onMenuClose={(e) => {
                          e && e.stopPropagation && e.stopPropagation(); // If event exists, stop its propagation
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="card_var_footer">
              <div className="product_btns">
                <div className="product_atc_btn" onClick={submitAddToCart}>
                  <img src={CartIcon} alt="icon icon-cart" />

                  <div className="btn_text">Add To Cart</div>
                </div>
                <div className="view_product_btn">
                  <Link
              to={`/product?type=productvariant&productid=${product.id}&variant1Value=${product.Variant1Value}&variant2Value=${product.Variant2Value}`}
              >
                    View Product
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchCard;
