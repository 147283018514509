import React from 'react';
import ReturnIcon from './../../../../assests/icons/ReturnIcon.svg'
import {CANCEL_ORDER} from "../../../../Constants";
import { useNavigate } from 'react-router-dom';

const PopUp = ({setConfirmPopUp,orderId,returnId}) => {

  const navigate= useNavigate();
    const cancelOrder = async () => {
        try {

            const payload = new URLSearchParams({
                OrderId: orderId 
              });
        
          const response = await fetch(CANCEL_ORDER, {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body:  payload
          });
     
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
     
          
          const data = await response.json();
          // console.log(data.Status)
          if(data.Status){
            navigate('/account/orders')
          }
         
         
        } catch (error) {
          console.error('Error fetching data:', error);
          throw error;
        }
      };
    const handleCancel = () => {
        cancelOrder()
        setConfirmPopUp(false) 
    }
    const handleDoNotCancel = () => {
        setConfirmPopUp(false)
    }
    if(returnId)
    {
      return (
        <div className="confirmCOD-modalWrapper">
    <div className="confirmCOD-modalContent inLine">
    <img src={ReturnIcon} className='confirmIcon'/>
      <div  className="confirmText"> Return Your Order</div>
      <div  className=' inLine'> <p className='text'>For return inquiries, please contact our customer support team at <span className='discount_price inLine'>1800 202 5157</span> </p> </div>
     
      <div className="buttons">
    
      <button className="home-button" onClick={handleDoNotCancel}>Close</button>
      </div>
      
    </div>
  </div>
      )
    }
    else {
  return (
    <div className="confirmCOD-modalWrapper">
    <div className="confirmCOD-modalContent">
      
      <div  className="confirmText"> Confirm to Cancel Your Order</div>
      <div className="buttons">
      <button className="home-button" onClick={handleCancel} >Yes,Cancel Order</button>
      <button className="order-button" onClick={handleDoNotCancel}>No, Do not Cancel</button>
      </div>
      
    </div>
  </div>
  )
}
}

export default PopUp
