import React from 'react';
import "./orderHistoryItem.css";
import { Link } from 'react-router-dom';

function OrderStatusItem({ orderDetail }) {
  //console.log(orderDetail)



  return (





    <div className="orderDetailsWrapper">
      <div className="detailBox">
        <Link to={`/product?type=productvariant&productid=${orderDetail.ProductId}&variant1Value=${orderDetail.variant1_value}&variant2Value=${orderDetail.variant2_value}`}>
        <div className="detailsContent">
          <div className="history__item__image">
            <img src={orderDetail.Image[0].ImageUrl} alt="No Image" className='imgShow'  loading="lazy"
             />
          </div>
          <div className="orderDetails">
            <div className="history__item__title">{orderDetail.Title}</div>
            <div className="actual_price">₹{ orderDetail?.ProductQuantity ? orderDetail.Price/orderDetail?.ProductQuantity : orderDetail.Price }</div>
           {orderDetail.variant1_value &&  <div className="orderedDate">{orderDetail.variant1_value ? ` ${orderDetail.variant1_name} : ${orderDetail.variant1_value}`: '' } {orderDetail.variant2_value ? `, ${orderDetail.variant2_name} : ${orderDetail.variant2_value}`: '' }</div>}
            <div className="orderedDate">Quantity : {orderDetail?.ProductQuantity} </div>

          </div>
        </div>
        </Link>
      </div>
    </div>

  )
}

export default OrderStatusItem




