import React, { useEffect, useState } from "react";
import BagIcon from "../../assests/icons/Bag3.png";
import BuyIcon from "../../assests/icons/Buy.png";
import { useCart } from "../layout/cart/cartContext"; // Adjust path to your CartContext
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { setCookie, getCookie } from "../../utils/cookieUtils"; // Adjust path to your cookieUtils
import {
  fetchRegisterId,
  fetchUserData,
  ADD_TO_CART,
  WEB_CARTLIST,
} from "../../Constants";
import Cookies from "js-cookie";
import addProductToCookies from "../layout/cart/addProductToCookies";
import fetchProductQuantityInCartList from "../../api/fetchProductQuantityInCartList";

const API_URL = ADD_TO_CART;
const WebCartList = WEB_CARTLIST;

const SubmitButtons = ({
  productId,
  variant1Value,
  variant2Value,
  quantity,
  product,
  setCountItems,
  setIsToastMessage,
  trackPageView
}) => {
  const { dispatch } = useCart();
  const [itemCount, setItemCount] = useState(0);
  const navigate = useNavigate();


const handleCart = async (quantityy) => {
  let result,quantities=4;
  if(fetchRegisterId()){
    if(quantityy){
      // console.log(" quantity:",quantity);
      result=await fetchProductQuantityInCartList(product.Result[0].id || productId,  variant1Value?.replace(/\+/g, " ") ||
      product.variant1Value?.replace(/\+/g, " "),
    variant2Value?.replace(/\+/g, " ") ||
      product.variant2Value?.replace(/\+/g, " "),quantity);
    // console.log(result);
    }else{
       result=await fetchProductQuantityInCartList(product.Result[0].id || productId,  variant1Value?.replace(/\+/g, " ") ||
    product.variant1Value?.replace(/\+/g, " "),
  variant2Value?.replace(/\+/g, " ") ||
    product.variant2Value?.replace(/\+/g, " "));
  // console.log(result);
    }
    
    if(result) {
      // console.log("adding quantity finally:",quantity)
      await addToCart();
    }
  }
  else{
     // Handle scenario when user is not logged in
     const isNotLoggedProduct = product.Result[0].id;
     const isNotLoggedQuantity = document.querySelector(
       ".quantity__selector .input__value input"
     )?.value;

     if (isNotLoggedProduct && isNotLoggedQuantity) {
       let reqproduct = product?.Result[0];
      //  console.log("reqproduct", reqproduct);
       const requiredVariantData = reqproduct.PriceQuantity[0];
      //  console.log("isNotLoggedQuantity", isNotLoggedQuantity);
       if(addProductToCookies(
         reqproduct.Name,
         reqproduct.id,
         isNotLoggedQuantity,
         requiredVariantData?.ListingPrice || 0,
         requiredVariantData?.MRP || 0,
         requiredVariantData.Note || "",
         requiredVariantData?.pvid || null,
         variant1Value,
         variant2Value,
         requiredVariantData?.CoverImage?.[0].image
       )){
         setIsToastMessage(true);
         setTimeout(() => {
           setIsToastMessage(false);
         }, 3000);
       }
 
       
     }
     setCountItems((prev) => parseInt(prev) + 1);
  }
 
}
  const addToCart = async () => {
    // if (fetchUserData()?.id) { // Check if user is logged in
    let RegisterId = fetchRegisterId();
    trackPageView();
    if (RegisterId) {
      try {
        const response = await fetch(API_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ProductId: product.Result[0].id || productId,
            RegisterId,
            ProductQuantity: quantity,
            variant1_value:
              variant1Value?.replace(/\+/g, " ") ||
              product.variant1Value?.replace(/\+/g, " "),
            variant2_value:
              variant2Value?.replace(/\+/g, " ") ||
              product.variant2Value?.replace(/\+/g, " "),
          }),
        });

        if (!response.ok) {
          throw new Error(
            `Failed to add product to cart! Status: ${response.status}`
          );
        }

        const result = await response.json();
        setIsToastMessage(true);
        setCountItems((prev) => parseInt(prev) + 1);

        // Dispatch action to update cart state
        dispatch({
          type: "ADD_TO_CART",
          payload: {
            id: productId,
            ...result, // If your API returns the updated cart item details
          },
        });

         // Ensure cart items are updated after adding

        // Notify user of successful addition
        // toast.success('Product added to cart successfully!');
      } catch (error) {
        console.error("Error adding product to cart:", error);
        // Notify user of error
        toast.error("Failed to add product to cart. Please try again.");
      } finally {
        setTimeout(() => {
          setIsToastMessage(false);
        }, 3000);
      }
    } 
  };


  const handleBuyNowClick = async () => {
    try {

      await handleCart(quantity);
      // Navigate to the checkout page or desired page
      navigate("/cart");
    } catch (error) {
      console.error("Error processing Buy Now:", error);
    }
  };
  const productItem = product.Result[0];
  const inStock = productItem.PriceQuantity?.[0]?.InStock == "yes";

  return (
    <div
      className={`submit__buttons__container ${
        !inStock ? "outstock_item" : ""
      }`}
    >
      <div className="submit__buttons flex">
        <div
          className="btn_primary buy_now_btn"
          onClick={inStock ? handleBuyNowClick : null}
          disabled={!inStock}
        >
          <img src={BagIcon} alt="Buy Now" /> Buy Now
        </div>
        <div
          className="btn_primary add_to_cart"
          onClick={inStock ? handleCart : null}
          disabled={!inStock}
        >
          <img src={BuyIcon} alt="Add to Cart" /> Add To Cart
        </div>
      </div>
    </div>
  );
};

export default SubmitButtons;
