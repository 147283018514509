import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// import { Link } from "react-router-dom";
// import { ReactComponent as Arrow } from "../../../assests/icons/arrowTop.svg";
// import { ReactComponent as GridView } from "../../../assests/icons/gridview.svg";
// import { ReactComponent as ListView } from "../../../assests/icons/listview.svg";
// import ArrowTop from "../../../assests/icons/greyArrow.svg";
import { data_not_found } from "../../../utils/image";
import SearchCard from "./searchCard";
// import Breadcrumbs from "../breadcrumb/breadcrumb";
import { SEARCH_DETAIL, WEB_CATEGORY_LIST } from "../../../Constants";

import "./searchBar.css";
// import ProductCard from "../../layout/product/productCard/productCard";
import CustomLoader from "../customLoader/customLoader";
const SearchFilter = ({ displayProductList, doesAPIHaveFilter, setCountItems}) => {
  const [categories, setCategories] = useState([]);
  const [currentSelected, setCurrentSelected] = useState([]);
  const [displayList, setDisplayList] = useState([]);
const [loading ,setLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchValue = queryParams.get("query") || ""; // Extract 'query' parameter


  const SearchKeywordDetails = async (searchName) => {
    try {
      const url = SEARCH_DETAIL;
      const payload = new URLSearchParams({ SearchName: searchName });
      setLoading(true)
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: payload,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setLoading(false)
      return data.Result;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };
  useEffect(() => {
    if (searchValue) {
      const fetchDetails = async () => {
        const result = await SearchKeywordDetails(searchValue);
        setDisplayList(result);
      };
      fetchDetails();
    }
  }, [searchValue]);

  const fetchCategory = async () => {
    const apiUrl = WEB_CATEGORY_LIST;
    try {
      const navResponse = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (!navResponse.ok) {
        throw new Error(`HTTP error! status: ${navResponse.status}`);
      }
      const navData = await navResponse.json();

      setCategories(navData.Result);
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);
  // let brandsData=[];
 // const [loading, setLoading] = useState(false);
  const [isGridViewActive, setIsGridViewActive] = useState(true); // Default active state

  const toggleGridView = () => {
    setIsGridViewActive(true);
  };

  const toggleListView = () => {
    setIsGridViewActive(false);
  };

  const handleInitialAddToCart = (product) => {
    setCurrentSelected((prev) => {
      if (prev.includes(product.id)) {
        return prev.filter((each) => each !== product.id);
      } else {
        return [product.id];
      }
    });
  };
  // console.log("displayList", setCountItems);
  if(loading){
    return (
      <CustomLoader/>
    )
  }
  else{
    return (
      <div className="brand__details__filter">
        {/* <div className="breadcrumbs page-width">HOME <img src={ArrowTop} className='arrow' /> SEARCH <img src={ArrowTop} className='arrow' /> {searchValue.toUpperCase()}</div> */}
        <div className="brand__filter__wrapper page-width">
          <main className="brand__product-list">
            <div className="brand__detail__breadcrumb page-width"></div>
            <div className="brand__product-list-header">
              <div className="brand__item_count">
                {/* <span className="brand__count"> {displayProductList.length} </span>items in */}
                <h1 className="brand__count__name"> {searchValue} </h1>
              </div>
            </div>
            {displayList.length > 0 ? (
              <div
                className={`brand__product-grid grid__view ${
                  !doesAPIHaveFilter ? "no_filter" : ""
                } ${isGridViewActive ? "grid__view" : "list__view"}`}
              >
                {displayList &&
                  displayList.map(
                    (product) =>
                      product.name &&
                      product.type === "product" && (
                        <>
                          <SearchCard
                            key={product.id}
                            product={product}
                            setCountItems={setCountItems}
                            onAddtoCart={handleInitialAddToCart}
                            currentSelected={currentSelected}
                            isOutOfStock={product?.InStock == "no"}
                          />
                        </>
                      )
                  )}
              </div>
            ) : (
              <div className="not_data_found_wrap" style={{ width: "100%" }}>
                <div className="main_data_container">
                  <div className="img_found_wrap">
                    <img src={data_not_found} alt="img" />
                  </div>
                  <div className="not_found_text">
                    <h5>Oops.. No Data Found!</h5>
                  </div>
                </div>
              </div>
            )}
          </main>
        </div>
      </div>
    );
  }
 
};

export default SearchFilter;
