import React, { useEffect, useState } from "react";
import PlusIcon from "../../../assests/icons/plus.svg";
import MinusIcon from "../../../assests/icons/minus.svg";
import {
  WEB_CART_DELETE,
  WEB_CART_QUANTITY_UPDATE,
  fetchRegisterId,
} from "../../../Constants";
import fetchCartListApi from "../../../api/fetchCartListApi";



const CartItem = ({
  onItemRemove = () => {},
  onUpdate = () => {},
  setCountItem,
  countItems,
  paymentType
}) => {
  const [sessionError, setSessionError] = useState(null);
  const [items, setItems] = useState([]);
//console.log(paymentType);
  // useEffect(() => {
  //  // console.log(paymentType);
  //   fetchCartItems(paymentType);
  // }, [paymentType]);

  const fetchCartItems = async (paymentType) => {
  

    try {
      let response;
      let registerId = fetchRegisterId();
   

      // if (registerId) {
      //   response = await fetch(WEB_CARTLIST, {
      //     method: "POST",
      //     headers: { "Content-Type": "application/json" },
      //     body: JSON.stringify({ RegisterId: registerId,PaymentType:'Prepaid',ApplicationType:'web' }),
      //   });
      // }
  
      //console.log(paymentType);
           response = await fetchCartListApi(paymentType);
         // console.log(response);
    
        
      
    
      const cartItemsData = response;
      setItems(cartItemsData || {});
      setCountItem(cartItemsData.Result);
    } catch (error) {
      setSessionError(error.message);
    }
  };

  const handleQuantityChange = async (newQuantity, itemId) => {
    try {
     

      const response = await fetch(WEB_CART_QUANTITY_UPDATE, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          RegisterId:fetchRegisterId(),
          ProductQuantity: newQuantity,
          CartListId: itemId,
        }),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to update quantity! Status: ${response.status}`
        );
      }

      await fetchCartItems();
    } catch (error) {
      console.error("Error updating quantity or fetching cart list:", error);
    }
  };

  const handleRemoveClick = async (cartListId) => {
    try {
   

      const response = await fetch(WEB_CART_DELETE, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
        
          CartListId: cartListId,
        }),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to remove cart item! Status: ${response.status}`
        );
      }

      const updatedItems = countItems.filter((item) => item.id !== cartListId);
      setCountItem(updatedItems);
      if (typeof onItemRemove === "function") {
        onItemRemove(updatedItems.length);
      }
      if (typeof onUpdate === "function") {
        onUpdate(updatedItems);
      }

      fetchCartItems();
    } catch (error) {
      setSessionError(error.message);
    }
  };

  const incrementQuantity = async (itemId) => {
    const item = countItems.find((item) => item.id === itemId);
    if (item) {
      const newQuantity = parseInt(item.ProductQuantity, 10) + 1;
      await handleQuantityChange(newQuantity, itemId);
    }
  };

  const decrementQuantity = async (itemId) => {
    const item = countItems.find((item) => item.id === itemId);
    if (item && item.ProductQuantity > 1) {
      const newQuantity = parseInt(item.ProductQuantity, 10) - 1;
      await handleQuantityChange(newQuantity, itemId);
    }
  };

  const calculateTotalPrice = () => {
    return countItems.reduce(
      (total, item) => total + item.TotalDiscountPrice * item.ProductQuantity,
      0
    );
  };

  if (sessionError) {
    return <div>Error: {sessionError}</div>;
  }

  let handleNewTab = (item) =>{
    let productNewTabUrl = `${window.location.origin}/product?type=productvariant&productid=${item.ProductId}&variant1Value=${item.variant1_value?.replace(/ /g, "+")}&variant2Value=${item.variant2_value?.replace(/ /g, "+")}`;
    window.open(productNewTabUrl, "_blank");
  }

  return (
    <div className="cart__item__containers">
      {countItems?.map((item) => (
        <div
          key={item.id}
          className="cart__item__content"
          data-line-item={item.id}
        >
          <div className="cart__item__container flex">
            <div className="cart__item__image">
              {item.Image && item.Image[0] && (
                <img src={item.Image[0].ImageUrl} alt="cart-item" />
              )}
            </div>
            <div className="cart__item__contents">
              <div className="cart__item__title" onClick={()=>handleNewTab(item)}>{item.PorductName}</div>
              <div className="cart__item__price flex gap5">
                <div className="actual_price">₹{item.TotalPrice}</div>
                {item.PriceQuantity && (
                  <div className="compare_price">
                    <s>₹{item.PriceQuantity[0].Mrp}</s>
                  </div>
                )}
                {item.Note && (
                  <div className="cart__item__discounts green">
                    <span>{item.Note}</span>
                  </div>
                )}
              </div>
              <div className="cart__item__variant grey">
                    <p>
                      {" "}
                      {item.variant1_name}: {item.variant1_value}{" "}
                    </p>
                    {item.variant2_name && (
                      <p>
                      {" "}
                      {item.variant2_name === "Size" ? "Size" : ""}:{" "}
                      {item.variant2_value}{" "}
                    </p>
                    )}
              </div>
              <div className="cart__item__buttons flex gap10">
                <div className="cart__quantity">
                  <div className="quantity__selector">
                    <div
                      className="plus__btn"
                      // onClick={() => incrementQuantity(item.id)}
                      disabled={true}
                    >
                      <img src={PlusIcon} alt="plus" />
                    </div>
                    <div
                      className="input__value"
                      data-value={item.ProductQuantity}
                    >
                      {item.ProductQuantity}
                    </div>
                    <div
                      className="minus__btn"
                      // onClick={() => decrementQuantity(item.id)}
                      disabled={true}
                    >
                      <img src={MinusIcon} alt="minus" />
                    </div>
                  </div>
                </div>
                {/* <div
                  className="cart__remove"
                  onClick={() => handleRemoveClick(item.id)}
                >
                  <RemoveButton />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CartItem;
