import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../common/breadcrumb/breadcrumb";
import { Link } from "react-router-dom";
import {
  fetchBlogListData,
  fetchRecommenedBlogListData,
} from "../../../api/BlogApi"; // Ensure the path is correct
import RecommendatedBlogs from "../blogs/blogRecommend";
import { ReactComponent as Line } from "../../../assests/icons/line.svg";
import { data_not_found } from "../../../utils/image";
import MetaTagsComponent from "../metaTag/metaTagComp";

import "./blog.css";

const BlogPosts = () => {
  const [activeFilter, setActiveFilter] = useState("All"); // Default to "All"

  //let filters ;//= ["ALL", "NUTRITION", "Supplementation", "Training"];

  const [loading, setLoading] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const [recommended, setRecommended] = useState([]);
  const [filters, setFilters] = useState([]);
  const [blogFilterData, setBlogFilterData] = useState([]);

  const [error, setError] = useState(null);
  useEffect(() => {
    const loadBlogListData = async () => {
      try {
        setLoading(true);
        const data = await fetchBlogListData();

        setBlogData(data.Result || []);
        //changeFilter();
      } catch (err) {
        setError("Failed to fetch blog data.");
      } finally {
        setLoading(false);
      }
    };

    const loadRecommendedData = async () => {
      let recommendedBlog = await fetchRecommenedBlogListData();
      setFilters(recommendedBlog.BlogCategory);
      setRecommended(recommendedBlog.BlogPost);
      //console.log(recommendedBlog);
    };

    loadBlogListData();
    //loadBlogCategoryData();
    setLoading(true);
    loadRecommendedData();
    setLoading(false);
  }, []);

  let BlogPost;
  const changeFilter = () => {
    if (activeFilter == "All") {
      BlogPost = blogData;

      setBlogFilterData(BlogPost);
    } else {
      BlogPost = blogData.filter((blog) => blog.BlogCategory == activeFilter);

      setBlogFilterData(BlogPost);
    }
  };
  useEffect(() => {
    setLoading(true);

    changeFilter();
    setLoading(false);
  }, [activeFilter, blogData]);

  let spaceRemoval = () => {
    let spaceRemove = document.querySelector(
      ".blog__breadcrumb span:last-child"
    );
    if (spaceRemove) {
      spaceRemove.innerHTML = spaceRemove.innerHTML.replace(/-/g, " ");
    }
  };

  // Call the function to perform the replacement
  spaceRemoval();
  //console.log(filters);
  return (
    <>
      <MetaTagsComponent />
      <div className="blog__post__wrapper">
        <div className="blog__post__container page-width">
          <div className="blog__post__heading">Blogs</div>
          <div className="blog__breadcrumb">
            <Breadcrumbs />
          </div>

          {/* Blogs Recommendation */}
          <RecommendatedBlogs recommended={recommended} />

          {/* Blogs Dot Line */}
          <div className="blog__post__line">
            <Line />
          </div>

          {/* Blogs Filter Array */}
          <div className="blogs__filter__buttons">
            {filters &&
              filters.map((filter) => (
                <div
                  key={filter?.id}
                  className={`blog__btn ${
                    activeFilter == filter?.CategoryName ? "active" : ""
                  }`}
                  onClick={() => setActiveFilter(filter?.CategoryName)}
                >
                  {filter.CategoryName}
                </div>
              ))}
          </div>

          {/* Blogs Main Item */}
        
            {loading ? (
              <div className="loader">Loading...</div>
            ) : blogFilterData.length > 0 ? (
              <div className="blog__post__content blog__post__main__content">
             { blogFilterData.map((post) => (
                
                <Link
                  to={`/blog/${post.Title.replace(/[^a-zA-Z0-9\s]/g, "")
                    .replace(/\s+/g, "-")
                    .toLowerCase()}?post_id=${post.id}`}
                  key={post.id}
                >
                  <div key={post.id} className="blog__card">
                    <img
                      src={post.WebImage}
                      alt={post.Title}
                      className="blog__card__image"
                    />
                    <div className="blog__card__content">
                      <div className="blog__card__header">
                        <div className="blog__author__details">
                          <img
                            src={post.UserProfile}
                            alt={post.UserName}
                            className="blog__author__image"
                          />
                          <div className="blog__author__name">
                            {post.UserName}
                          </div>
                        </div>
                        <span className="blog__card__category">
                          {post.BlogCategory}
                        </span>
                      </div>
                      <div className="blog__card__meta">
                        <span className="blog__card__date">
                          {post.PublishedAt}
                        </span>
                      </div>
                      <h2 className="blog__card__title">{post.Title}</h2>
                      <p
                        className="blog__card__description"
                        dangerouslySetInnerHTML={{
                          __html:
                            post.WebContent.length > 100
                              ? post.WebContent.slice(0, 100) + "..."
                              : post.WebContent,
                        }}
                      >
                        {/* {post.Content.length > 100 ? post.Content.slice(0, 100) + '...' : post.Content} */}
                      </p>
                      <Link
                        to={`/blog/${post.Title.replace(/[^a-zA-Z0-9\s]/g, "")
                          .replace(/\s+/g, "-")
                          .toLowerCase()}?post_id=${post.id}`}
                        className="blog__card__read-more"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </Link>
                
              ))}
              </div>
            ) : (
              <div className="not_data_found_wrap" style={{ width: "100%" }}>
                <div className="main_data_container">
                  <div className="img_found_wrap">
                    <img src={data_not_found} alt="img" />
                  </div>
                  <div className="not_found_text">
                    <h5>Oops.. No Data Found!</h5>
                  </div>
                </div>
              </div>
            )}
          
        </div>
      </div>
    </>
  );
};

export default BlogPosts;
